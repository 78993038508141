.breadcrumb-list {
  color: $secondary;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  list-style: none;
  margin: 0 0 units(2) 0;
  padding: 0;
}

.breadcrumb-list__item {
  display: flex;
  
  &:after {
    content: "/";
    display: inline-block;
    padding: 0 units(.5);
  }

  &:last-child:after {
    content: none;
  }
}

.breadcrumb__link,
.breadcrumb__link:visited {
  color: $secondary
}