.glossary {
    background: #212121;
    color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: scroll;
    padding: units(6) units(3) units(4);
    transition: left 0.2s;
    width: 100%;
    z-index: 9999;

    &[aria-hidden=true] {
        left: 100%;
    }
    div[aria-hidden=true] {
        display: none !important;
    }
    button {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        appearance: none;
        color: white;
        background: none;
        border: none;
    }
    
    @include tablet-s {
        left: auto;
        width: 30rem;
        padding-top: units(15);
        transition: right 0.2s;

        &[aria-hidden=true] {
            left: auto;
            right: -30rem;
        }
    
    }
}

.glossary--close {
    color: white;
    border: none;
    position: absolute;
    top: units(1);
    right: units(1);
    padding: units(1);
    
}

.glossary--title {
    font-size: 32px;
    font-family: $fjord;
}

.glossary__items {
    margin: 0;
    padding: 0;
}

.glossary__item {
    list-style: none;
    color: white;
    font-size: 16px;
    margin: 0 0 0 units(1);
    font-family: $open-sans;
    font-weight: 400;
    line-height: 1.625;
    border-top: 1px solid white;
    
}

.glossary__term {
    width: 100%;
    padding: units(2) 0;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    line-height: 1.6;

    .plus-minus {
        flex: 0 0 22px;
        padding-left: 10px;
    }

    &[aria-expanded="false"] {
        .plus { display: block; }
        .minus { display: none; }
    }
    &[aria-expanded="true"] {
        .plus { display: none; }
        .minus { display: block; }
    }
}

.glossary__definition {
    padding: 0 units(2) units(2) 0;
    &[aria-hidden="true"] {
        display:none;
    }
}