.masthead {
  background-color: $base-darker;
  color: $white; 

  a {
    text-decoration: none;
    color: $white;
  }
 
  .navbar {
    display: flex;
    align-items: center;
    min-height: 72px;
    padding-top: 20px;
    padding-bottom: 20px;

    @include tablet-s {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .navbar--noflex {
    display: block;
  }

  /* Screen reader only */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  .subtitle {
    margin: 0 auto 0 0;
    width: 80%;
  }

  svg {
    stroke: $white;
    height: auto;

    &.menu-open { width: 21px; }
    &.menu-close { width: 21px; }
  }

  /* Button styling */
  .menu-toggle {
    display: inline-block;
    color: #333;

    &:hover, &:focus { color: #c00; }

    @include tablet-s { display: none; }
  }

  /*
  Default styles + Mobile first
  Offscreen menu style
  */
  .main-menu {
    position: absolute;
    display: none;
    right: 500px;
    top: 0;
    height: 140px;
    overflow-y: scroll;
    overflow-x: visible;
    transition: right 0.75s ease;
    z-index: 999;

    @include tablet-s {
      position: relative;
      right: auto;
      top: auto;
      height: auto;
      display: block;
      overflow: hidden;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 45px 0 0;
      min-height: 100%;
      width: 325px;
      background: $primary-darker;

      @include tablet-s {
        align-items: center;
        background: none;
        display: flex;
        min-height: auto;
        padding: 0 units(1.5) 0 0;
        width: auto;
      }

      li {
        text-align: right;
      }
    }

    a {
      border-bottom: 2px solid transparent;
      color: $white;
      display: block;
      margin: 10px 20px;
      padding-bottom: 5px;
      text-decoration: none;
      white-space: nowrap;
      
      @include tablet-s {
        &:last-of-type { 
          margin-right: 0; 
        }
      }

      &:hover, &:focus {
          background: none;
          color: $white;

      &:hover { 
        border-bottom: 2px solid $white; 
      }

      }
    }

    .menu-close {
      position: absolute;
      right: 10px;
      top: 10px;
      margin: 0;

      @include tablet-s { display: none; }
    }

    &:target, &[aria-expanded="true"] {
      display: block;
      right: 0;
      outline: none;
      height: 100vh;

      ul {
        position: relative;
        z-index: 1000;
      }
    }

    &:target .menu-close,
    &[aria-expanded="true"] .menu-close {
      z-index: 1001;
    }

    &:target + .backdrop,
    &[aria-expanded="true"] + .backdrop {
      position: absolute;
      display: block;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 998;
      background: #000;
      background: rgba(0,0,0,.85);
      cursor: default;
    }

    @supports (position: fixed) {
      .main-menu,
      .main-menu:target + .backdrop,
      .main-menu[aria-expanded="true"] + .backdrop {
        position: fixed;
      }
    }
  }

  .page--external & {
    padding-bottom: units(4);
    .subtitle {
      @include desktop {
        font-size: 48px;
        padding-top: units(4);
      }
    }
  }
}
