.skipnav {
  background: transparent;
  font-size: 16px;
  left: 0;
  padding: units(1) units(2);
  position: absolute;
  top: -3.8rem; // skipnav link height
  transition: all 0.2s ease-in-out;
  z-index: 100;

  &:focus {
    background: white;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.2s ease-in-out;
  }
}