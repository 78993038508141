.pdf {
  height: 85vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landscape {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

iframe, embed {
  width: 100%;
  height: 100%;
}
