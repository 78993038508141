@import "_settings/border";
@import "_settings/color";
@import "_settings/responsive";
@import "_settings/paths";
@import "_settings/units";
@import "_settings/spacing";
@import "_settings/mixins";

@import "_functions/asset-paths";

@import "_includes/typography";
@import "_includes/base";

@import "_components/actions";
@import "_components/auth";
@import "_components/boxes";
@import "_components/buttons";
@import "_components/cards";
@import "_components/facets";
@import "_components/filters";
@import "_components/form-errors";
@import "_components/glossary";
@import "_components/hero";
@import "_components/icons";
@import "_components/masthead";
@import "_components/search";
@import "_components/breadcrumbs";
@import "_components/tags";
@import "_components/header";
@import "_components/footer";
@import "_components/pdfview";
@import "_components/richtext";
@import "_components/sidenav";
@import "_components/skipnav";
@import "_components/stats";
@import "_components/tabs";
@import "_components/timeline";
@import "_components/wagtail-django";

@import "_pages/about";
@import "_pages/global";
@import "_pages/home";
@import "_pages/policies";
@import "_pages/error";

@import "_utilities/grid";
@import "_utilities/margin";
@import "_utilities/padding";
@import "_utilities/visibility";

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  background: $white;
  font-size: 1.6rem;
}

.page--auth {
  .auth-hide {
    display: none;
  }
}

// JSK: Temporary until we fix tags
// .tags {
//   margin: 5px 0;
//   max-height: 0;
//   overflow: hidden;
//   visibility: hidden !important;

//   .show-tags & {
//     margin: 20px 0;
//     max-height: none;
//     overflow: auto;
//     visibility: visible !important;
//   }
// }