// default colors
$white: #ffffff;
$black: #000;
$visited-link-color: #FA0B70;

$base: #494f51;
$base-darker: #2e3638;
// $base-light: #737778;
$base-light: #696C6D;
$base-lighter: #898d8e;
$base-lightest: $white;
$base-darkest: #060606;

$primary: #4e5f8a;
$primary-light: #6c7690;
$primary-lighter: #959fb9;
$primary-darker: #324882;
$primary-vivid: #2f54ba;

$secondary: #922846;
$secondary-darker: #790022;
$secondary-lighter: #b87387;
$secondary-vivid: #bc1d4a;

$tertiary: #EEA02F;
$tertiary-dark: #836B14;

$accent-cool: #eef7fe;
$accent-cool-darker: #cedfec;
$accent-cool-vivid: #c0e0fa;

$accent-darker: #d6d6d6;
$accent-light: #ededed;

