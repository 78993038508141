.bio {
  justify-content: space-between;
}

.bio__desc {
  order: 2;

  @include tablet-s {
    order: unset;
  }
}

.bio__image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 150px;
  width: 100%;

  @include tablet-s {
    margin-top: units(4);
    max-width: none;
  }

}